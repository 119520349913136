<template>
  <div
    :style="height"
  >
    <script
      type="application/javascript"
      :src="componentJS"
    />
    <loading-overlay :loading="inProgress">
      <bcf-customizer
        :themes="themes"
        :model-code="model"
        :code="code"
        :language="language"
        :selections="selections ? JSON.stringify(selections) : null"
        :max-height="maxHeight"
        :api="api"
        :dealer="selectedCustomer"
        :target="target"
        :currency="currency"
        :only-colors="colorchange"
        @add="addToCart"
        @statisticsEvent="processStatisticsEvent"
      />
    </loading-overlay>
    <bcf-modal
      v-if="cartModal"
      class="modal--addedToCart"
      @close="closeCartModal"
    >
      <bcfAsideHeader :close="closeCartModal">
        {{ $t('cart.inCart') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <p class="m-t-2">
          {{ $t('cart.successAddedToCart') }}
        </p>

        <SectionTitle>{{ $t('cart.successNextSteps') }}</SectionTitle>

        <list-item icon-before>
          <i class="uil uil-arrow-circle-right" />
          <a
            href="#"
            @click="closeCartModal"
          >{{ $t('cart.continueShopping') }}</a>
        </list-item>
        <list-item icon-before>
          <i class="uil uil-shopping-cart" />
          <router-link :to="{name:'cart'}">
            {{ $t('cart.toCart') }}
          </router-link>
        </list-item>
      </bcfAsideContent>
    </bcf-modal>
  </div>
</template>
<script>
// components
import { mapState, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { LoadConfigurationByCode, saveConfiguration } from '@/api/customizer';
import bcfModal from '@/components/bcfModal.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import ListItem from '@/elements/ListItem.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';

export default {
  name: 'BuildYourOwn',
  components: {
    bcfModal,
    bcfAsideHeader,
    bcfAsideContent,
    ListItem,
    loadingOverlay,
  },
  data() {
    return {
      inProgress: true,
      selections: null,
      load: null,
      code: null,
      height: null,
      maxHeight: null,
      componentJS: process.env.VUE_APP_CUSTOMIZER_JS,
      api: process.env.VUE_APP_BASE_URL,
      cartModal: false,
      colorchange: false,
      size: 'M',
      salesorder: null,
      linenr: null,
    };
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateHeight);
  },
  computed: {
    ...mapGetters('cart', {
      cartCustomizerItem: 'cartCustomizerItem',
    }),
    ...mapState({
      language: (state) => state.language.currentApi,
      target: (state) => {
        let target = 'B2C';
        if (state.auth.user) {
          switch (state.auth.user.type) {
            case 5: target = 'internal'; break;
            case 4: target = 'internal'; break;
            case 2: target = 'REP'; break;
            default: target = 'B2B';
          }
        }
        return target;
      },
      currency: (state) => state.customer.selectedCustomer.attributes.currency,
      selectedCustomer: (state) => {
        const cus = state.customer.selectedCustomer;
        return JSON.stringify({
          name: cus.name,
          externalid: cus.externalid,
          discounts: cus.attributes.discounts,
          showRetail: state.items.showRetailPrices,
          exchangerate: cus.attributes.exchangerate,
        });
      },
    }),
    ...mapGetters('cart', {
      cartCustomizerItem: 'cartCustomizerItem',
    }),
    model() {
      return this.$route.params.model;
    },
    themes() {
      return ['b2b'];
    },
  },
  async created() {
    window.addEventListener('resize', this.calculateHeight);
    this.calculateHeight();
    this.load = this.$route.query.load;
    this.code = this.$route.query.code;
    this.colorchange = this.$route.query.colorchange;
    this.size = this.$route.query.size;
    this.salesorder = this.$route.query.o;
    this.linenr = this.$route.query.l;
    let item = {};
    if (this.load) {
      item = this.cartCustomizerItem(this.load);
      if (item) {
        this.selections = item.customizer.selections;
      }
    } else if (this.code) {
      item = await LoadConfigurationByCode(this.code);
      if (item) {
        // TO-DO: same version check(add version to save as favorite)
        this.selections = item.configuration;
      }
    } else if (this.colorchange && this.size) {
      this.selections = { R020_Size: this.size };
    }
    this.inProgress = false;
  },
  methods: {
    closeCartModal() {
      this.cartModal = false;
    },
    calculateHeight() {
      this.maxHeight = document.documentElement.clientHeight - (document.documentElement.clientWidth > 991 ? 76 : 56);
      this.height = `height: ${this.maxHeight}px`;
    },
    processStatisticsEvent(event) {
      if (window.blueConicClient) {
        window.blueConicClient.event.publish(event.detail[0], [{
          code: event.detail[1].code,
          family: event.detail[1].family,
          configurationObject: event.detail[1].family,
          configurationObjectLocalized: event.detail[1].family,
          name: event.detail[1].name,
          platform: event.detail[1].platform,
          readableConfiguration: event.detail[1].readableConfiguration,
          target: event.detail[1].target,
          brand: event.detail[1].brand,
          email: event.detail[1].email,
          configurationId: event.detail[1].configurationId,
        }]);
      }
    },
    async addToCart(e) {
      this.inProgress = true;
      const item = e.detail[0];
      const i = item.selections;
      const l = item.selectionsLocalized;
      let configuration = null;
      if (item.favorite) {
        configuration = item.favorite;
        console.log(`[AddToCart] Configuration already saved as: ${configuration.code}`, ({ configuration, event: e }));
        this.inProgress = false;
      } else {
        try {
          configuration = await saveConfiguration(this.model, item.selections);
          console.log(`[AddToCart] Saved configuration as new Configuration Code: ${configuration.code}`, ({
            configuration,
            event: e,
          }));
        } catch (err) {
          // TODO: Shouldn't this abort further execution and notify the user of failure?

          console.log(err);
        } finally {
          this.inProgress = false;
        }
      }
      const stats = item.statisticsData || {}; // This is the same info we normally send in ConfigSaved, ConfigCompleted events to BC
      const cusItem = {
        id: 0,
        externalid: this.load ? this.load : uuidv4(),
        description: item.title || `${l.R010_Model ? l.R010_Model.value : i.R010_Model} ${l.R020_Size ? l.R020_Size.value : i.R020_Size} ${l.R030_Design ? l.R030_Design.value : i.R030_Design} ${l.R040_Groupset ? l.R040_Groupset.value : i.R040_Groupset} customized`,
        attributes: {
          item_brand: item.model.attributes.item_brand,
          category: 'custom',
          platform: stats.platform,
          family: stats.family,
          range: stats.range,
        },
        subtitle: item.model.attributes.item_brand,
        title: item.model.attributes.model,
        tags: [l.R040_Groupset ? l.R040_Groupset.value : i.R040_Groupset, l.R030_Design ? l.R030_Design.value : i.R030_Design, 'Custom bike'],
        image: configuration ? (configuration.images && configuration.images.print && configuration.images.print['1x']) : null,
        frameimage: configuration ? (configuration.images && configuration.images.frame && configuration.images.frame['1x']) : null,
        variantattribute: {
          attribute: 'frame_size',
          value: l.R020_Size ? l.R020_Size.value : i.R020_Size,
        },
        deliverydate: item.date,
        $$price: item.price,
      };
      const customizer = {
        selections: i,
        model: item.model.code,
        version: item.model.version,
        target: this.target,
        code: configuration ? configuration.code : null,
      };

      if (this.load) {
        this.$store.dispatch('cart/updateCustomizerInCart', { product: cusItem, customizer, key: this.load });
        this.$router.push({ name: 'cartCustomizerItem', params: { key: this.load } });
      } else if (this.colorchange) {
        this.$router.push(`/account/orders/${this.salesorder}?linenr=${this.linenr}&c=${configuration.code}`);
      } else {
        this.$store.dispatch('cart/addProductToCart', { product: cusItem, customizer });
        this.cartModal = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap&family=Barlow:wght@300;400;700&family=Staatliches&display=swap");

.customizerWrapper {
  height: calc(100vh - 76px);
  max-height: calc(100vh - 76px);
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
  }
}
</style>
